import React from 'react';
import './App.css';

function App() {
  const me = {
    name: 'Jason Hu',
    job: 'Software Engineer',
  };
  return (
    <div className="App">
      <pre>{JSON.stringify(me, null, 2)}</pre>
    </div>
  );
}

export default App;
